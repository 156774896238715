<template>
  <div>
    <loading
      :active.sync="isLoading"
      background-color="#332abb"
      :opacity="0.99"
      color="#fff"
      :is-full-page="fullPage"
    ></loading>

    <ModalBagisYap ref="modalBagisYap"></ModalBagisYap>

    <MainBanner
      v-if="this.infographic"
      @clicked="showModal"
      :SmallTitle="this.infographic.desc"
      :Title="this.infographic.student.toLocaleString('en')"
      :countCity="this.infographic.city"
      :countTeacher="this.infographic.teacher"
      :countSchool="this.infographic.school"
      :countStudent="this.infographic.student"
      Desc=""
      ButtonText="Bağış Yap"
      :MultipleImage="true"
      TextPosition="between"
      Height="full"
    />
    <section class="kampanya-wrapper">
      <div class="container">
        <div class="d-flex align-items-center justify-content-between flex-column flex-md-row">
          <div class="flex-1 d-flex flex-column flex-md-row mobile-full">
            <div class="flex-1 mr-3 form-group position-relative search-input input-icon d-inline-flex align-items-center">
              <input
                type="text"
                class="form-control"
                placeholder="Kampanya Ara"
                v-on:keyup="separeteCards()"
                v-model="searchWord"
              />
              <span class="svg-icon icons-search" @click="filterIt()"></span>
            </div>
            <select
              class="custom-selectbox mr-0 mr-md-3 flex-1"
              v-model="selectedCity"
              v-if="cityList.length > 0"
              @change="separeteCardsByCity()"
            >
              <option value="" selected>İl Seçiniz</option>
              <option v-for="(city, index) in cityList" :key="index" :value="city.id">
                {{ city.title }}
              </option>
            </select>
          </div>

          <ul class="nav nav-tab d-flex flex-1 mt-5 mt-md-0">
            <li class="nav-item w-50 text-center position-relative">
              <a
                class="d-flex align-items-center justify-content-center active"
                data-toggle="tab"
                href="#acik-kampanyalar"
                aria-controls="acik-kampanyalar"
                aria-selected="true"
                >Açık Kampanyalar
                <span class="svg-icon icons-arrow-down"></span>
              </a>
            </li>
            <li class="nav-item w-50 text-center position-relative">
              <a
                class="d-flex align-items-center justify-content-center"
                data-toggle="tab"
                href="#tamamlananlar"
                aria-controls="tamamlananlar"
                aria-selected="false"
                >Tamamlananlar
                <span class="svg-icon icons-arrow-down"></span>
              </a>
            </li>
          </ul>
        </div>
        <div class="kampanya-container tab-content">
          <div
            class="tab-pane fade show active"
            v-if="openCampaign.length > 0"
            id="acik-kampanyalar"
            role="tabpanel"
          >
            <KampanyaCard :data="this.openCampaign" :key="renderAgain" />
          </div>
          <div
            class="tab-pane fade show"
            v-if="closedCampaign.length > 0"
            id="tamamlananlar"
            role="tabpanel"
          >
            <KampanyaCard :data="closedCampaign" :key="renderAgain" />
          </div>
        </div>
      </div>
      <div class="spacer clearfix"></div>
    </section>

    <FooterLanding />
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import MainBanner from "@/components/MainBanner";
import FooterLanding from "@/components/FooterLanding";
import ModalBagisYap from "@/components/ModalBagisYap";
import KampanyaCard from "@/components/KampanyaCard";
import ButtonRounded from "@/components/ButtonRounded";

export default {
  name: "Kampanyalar",
  components: {
    MainBanner,
    FooterLanding,
    Loading,
    ModalBagisYap,
    KampanyaCard,
    ButtonRounded,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      bagisSecTitle: "Ben Nasıl Destek Olabilirim?",
      bagisSecDesc:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam facilisis ornare arcu eget iaculis. <br> Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      kampanyaList: [],
      openCampaign: [],
      closedCampaign: [],
      cityList: {},
      searchWord: "",
      renderAgain: 1,
      selectedCity: "",
      infographic: '',
    };
  },
  computed: {
    filteredList() {
      return this.kampanyaList.filter((elm) => {
        return elm.kampanyaAdı
          .toLocaleLowerCase('tr-TR')
          .includes(this.searchWord.toLocaleLowerCase('tr-TR'));
      });
    },
    filteredItems() {
      return this.kampanyaList.filter((elm) => {
        return elm.kampanyaSehir === this.selectedCity;
      }, this);
    },
    width() {
      return "width:" + 80 + "%;";
    },
  },
    metaInfo() {
    return {
      title: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.',
      meta: [
        {
          vmid: "description",
          name: "description",
          //content: this.metaData.description,
        },
        // {name: 'keywords', content: this.meta_data.meta_keywords},
        // {property: 'og:image', content: this.meta_data.meta_image},
        { property: "og:title", content: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.' },
        { property: "og:description", content: 'Okullara geri dönen imkanları sınırlı, hayalleri sınırsız çocuklarımıza Bilim Seti ulaştırılması için destek ol!' },
        { property: "og:url", content: window.location.href },
        { name: "twitter:title", content: 'Bilim Seferberliği - İmkanları sınırlı, hayalleri sınırsız çocukları bilim ile buluştur.' },
        { name: "twitter:description", content: 'Okullara geri dönen imkanları sınırlı, hayalleri sınırsız çocuklarımıza Bilim Seti ulaştırılması için destek ol!' },
        { name: "twitter:url", content: window.location.href},
        // {name: 'twitter:image', content: this.meta_data.meta_image},
        // {property: 'og:image:width', content: '540'},
        // {property: 'og:image:height', content: '282'},
      ],
    };
  },
  mounted() {
    this.getList();
    this.getCityList();
    this.getInfographic();
  },
  methods: {
    getInfographic() {
      this.$api.getInfographic().then((response) => {
        this.infographic = response;
      });
    },
    showModal() {
      this.$refs.modalBagisYap.openModal();
      this.$refs.modalBagisYap.getSwiper();
    },
    getList() {
      this.$api.pageCampaignList().then((response) => {
        // console.log("reponse", response);
        
        const formattedData = response.map((item) => {
          return {
            kampanyaOlusturan: item.campaign_user.name + " " + item.campaign_user.surname,
            kampanyaAdı: item.detail.title,
            profilResmi: item.campaign_user.image,
            planlananBagis: item.detail.price,
            toplananBagis: item.total.donations_collected,
            progressValue: item.total.percent,
            kalanGun: item.detail.left_day,
            kampanyaId: item.detail.id,
            destekci: item.donation_users_total,
            kampanyaDurum: item.detail.complete_status,
            kampanyaSehir: item.detail.city_id,
            kampanyaSlug: item.detail.slug,
            sehirAdi: item.detail.city_name
          };
        });
        this.kampanyaList = formattedData;
        this.separeteCards();
      });
    },
    separeteCards() {
      this.renderAgain++;
      let open = [];
      let closed = [];
      this.filteredList.forEach(function (a) {
        if (a.kampanyaDurum === "open") {
          open.push(a);
        } else {
          closed.push(a);
        }
      });
      this.openCampaign = open;
      this.closedCampaign = closed;
    },
    separeteCardsByCity() {
      this.renderAgain++;
      let open = [];
      let closed = [];

      if (this.selectedCity === "") {
        this.kampanyaList.forEach(function (a) {
          if (a.kampanyaDurum === "open") {
            open.push(a);
          } else {
            closed.push(a);
          }
        });
      } else {
        this.filteredItems.forEach(function (a) {
          if (a.kampanyaDurum === "open") {
            open.push(a);
          } else {
            closed.push(a);
          }
        });
      }
      this.openCampaign = open;
      this.closedCampaign = closed;
    },
    getCityList() {
      this.$api.getCampaignCity().then((response) => {
        //tekrarları siliyoruz
        function multiDimensionalUnique(arr) {
          var uniques = [];
          var itemsFound = {};
          for (var i = 0, l = arr.length; i < l; i++) {
            var stringified = JSON.stringify(arr[i]);
            if (itemsFound[stringified]) {
              continue;
            }
            uniques.push(arr[i]);
            itemsFound[stringified] = true;
          }
          return uniques;
        }
        let cities = multiDimensionalUnique(response);
        this.cityList = cities;
      });
    },
  },
};
</script>

<style lang="scss">
.kampanya-wrapper {
  padding: 5rem 0;
  background: var(--whiteOld);
  position: relative;

  .form-group {
    input {
      background: #fff;
      max-width: 100%;
      max-height: 60px;
      height: 60px;
    }
  }

  .kampanya-container {
    padding-top: 2rem;
  }
  @media (max-width: 480px) {
    .home-banner {
      margin-top: 115px;
    }
    .form-group {
      width: calc(100vw - 30px);
      input {
        background: #fff;
        max-width: 100%;
      }
    }
    .mobile-full {
      width: 100%;
    }
    .search-input {
      width: 100% !important;
      input {
        width: 100%;
      }
    }
  }
}
</style>