<template>
  <div class="w-100">
    <div class="w-100 grid-column">

      <div class="d-flex" v-for="(itemIndex, id) in newData" :key="id">
        <router-link :to="{ name: 'KampanyaDetay', params: { slug: itemIndex.kampanyaSlug } }" class="kampanya-card d-flex flex-column justify-content-between">
          <div>
            <PuSkeleton :loading="loading">
              <h4 v-html="itemIndex.kampanyaAdı + (itemIndex.sehirAdi ? ' - ' + itemIndex.sehirAdi : '') "></h4>
              <!-- <h4>
                {{ itemIndex.kampanyaAdı }}
              </h4> -->
            </PuSkeleton>
            <PuSkeleton height="50px" :loading="loading">
              <UserCard
                class="my-3"
                fontColor="blue"
                :userName="itemIndex.kampanyaOlusturan"
                :image="itemIndex.profilResmi"
              />
            </PuSkeleton>
          </div>
          <div class="my-3">
            <PuSkeleton height="30px" :loading="loading">
              <h3 v-if="itemIndex.total_donation_show">{{ itemIndex.toplananBagis }}</h3>
            </PuSkeleton>
            <div class="planned d-flex align-itemIndexs-center" v-if="itemIndex.planlananBagis > 0">
              <PuSkeleton :loading="loading" class="d-flex">
                <p>{{ itemIndex.planlananBagis }} TL</p>
                <span> Planlanan Bağış</span>
              </PuSkeleton>
            </div>
            <ProgressBar v-if="itemIndex.planlananBagis > 0"
              class="my-3"
              :value="itemIndex.progressValue"
              bgColor="blue"
              size="mini"
            />
          </div>

          <div class="mt-auto">
            <div class="kampanya-info d-flex">
              <div v-if="itemIndex.kalanGun > 0" class="mr-4">
                <PuSkeleton :loading="loading">
                  <p>{{ itemIndex.kalanGun }}</p>
                  <span><span class="text-underline">Kalan</span> Gün <br> Sayısı</span>
                </PuSkeleton>
              </div>

              <div>
                <PuSkeleton :loading="loading">
                  <p>{{ itemIndex.destekci }}</p>
                  <span>Destekçi <br> katıldı</span>
                </PuSkeleton>
              </div>
            </div>
            <PuSkeleton :loading="loading" height="40px">
              <ButtonRounded
                class="w-100 mx-auto mt-4"
                :Text="(itemIndex.kampanyaDurum == 'completed' || itemIndex.kalanGun < 0) ? 'İncele' : 'Bağış Yap'"
                :isRouting="false"
                color="blue"
                btnSize="large"
              />
            </PuSkeleton>
          </div>
        </router-link>
      </div>
    </div>
    <div v-if="newData.length < totalCards" class="w-100 text-center">
      <!-- <button @click="loadMore()">show more reviews</button> -->
      <ButtonRounded
        class="mx-auto mt-5"
        Text="Daha Fazla"
        @click.native="cloneData()"
        :isRouting="false"
        btnSize="small"
      />
    </div>
  </div>
</template>

<script>
import UserCard from "@/components/UserCard";
import ButtonRounded from "@/components/ButtonRounded";
import ProgressBar from "@/components/ProgressBar";
import { Skeleton } from "vue-loading-skeleton";

export default {
  name: "KampanyaCard",
  components: {
    UserCard,
    ProgressBar,
    ButtonRounded,
    Skeleton,
  },
  props: ["data"],
  data() {
    return {
      cardsToShow: 8,
      totalCards: this.data.length,
      loading: true,
      newData: [],
      clonedData: this.data,
    };
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1500);
    this.cloneData();
  },
  methods: {
    cloneData() {
      this.newData.push(...this.clonedData);
      // if (this.clonedData.length < 9) {
        
      // } else {
      //   for (let i = 0; i < this.cardsToShow; i++) {
      //     let splicedData = this.clonedData.shift();
      //     this.newData.push(splicedData);
      //   }
      //   const string = "foo";
      //   const substring = "oo";

      //   if (string.includes(substring)) {

      //   } else {

      //   }
      // }

    },
  },
  watch: {
    dataToBeWatched: function (data) {
      //do something when the data changes.
      if (this.data) {
        // console.log("değişim");
      }
    },
  },
};
</script>

<style lang="scss">
.kampanya-wrapper {
  padding: 5rem 0;
  background: var(--whiteOld);

  .grid-column {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 1.5rem;
    row-gap: 1.5rem;
    display: grid;
    /*  IE BUG  */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* detect IE */
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > .d-flex{
        width: 25%;
        padding: 10px;
      }
      
    }
    /*  IE BUG  */
  }

  .kampanya-card {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    padding: 1.5rem;
    color: var(--purpleDark);

    h3 {
      font-size: 1.8rem;
      font-weight: 600;
      margin: 0.5rem 0;
      color: var(--orange);
    }
    h4 {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.3;
    }
    p {
      font-weight: 600;
    }
    span {
      font-size: 0.65rem;
    }

    .planned {
      p {
        font-size: 1rem;
        margin-right: 0.5rem;
      }
      span {
        font-size: 1rem;
      }
    }

    .kampanya-info {
      line-height: 1.2;
      span {
        font-size: 1rem;
        p{
          font-size: 1.4rem;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .grid-column {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  @media (max-width: 768px) {
    .grid-column {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (max-width: 567px) {
    .grid-column {
      grid-template-columns: 1fr;
    }
  }
}
</style>
